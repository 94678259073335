import React, {useEffect, useRef} from 'react';
import {useForm} from "react-hook-form";
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Welcome from './Welcome';

function LocationAwareComponent() {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/welcome') {
            document.title = 'LawEval Onboarding';
        } else if (location.pathname === '/') {
            document.title = 'LawEval Login';
        }
    }, [location]);

    // return null if this component shouldn't render anything
    return null;
}

function App() {
    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const subdomainRef = useRef(null);
    const redirectRef = useRef(null);

    const isValidSubdomain = (value) => {
        return true || 'Invalid subdomain.';
    };

    const { ref: ref1 } = register('subdomain', { required: true, validate: isValidSubdomain });

    const onSubmit = (data, event) => {
        event.preventDefault();

        if(redirectRef.current) {
            window.location.href = redirectRef.current.value;
        }
    }

    useEffect(() => {
        if (subdomainRef.current) {
            subdomainRef.current.focus()
        }
    }, [subdomainRef])

    return (
        <Router>
            <LocationAwareComponent />
            <Routes>
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/" element={
                    <div className="App">
                        <header className="App-header">
                            <img src={logo} className="App-logo" alt="logo"/>
                        </header>
                        <section className="App-content">
                            <div className="App-widget">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="App-input-wrapper">
                                        <input
                                            name="subdomain"
                                            ref={(e) => {
                                                ref1(e)
                                                subdomainRef.current = e // you can still assign to ref
                                                if(subdomainRef.current) {
                                                    //console.log(subdomainRef.current)
                                                }
                                            }}
                                            onChange={(e) => {
                                                setValue("subdomain", e.target.value)
                                                let subdomain = e.target.value;
                                                let filteredSubdomain = subdomain.trim()
                                                    .replaceAll("https://", "")
                                                    .replaceAll("http://", "")
                                                    .replaceAll(".laweval.app/", "")
                                                    .replaceAll(".laweval.app", "");
                                                if (redirectRef.current) {
                                                    redirectRef.current.value = "https://" + filteredSubdomain + ".laweval.app/";
                                                    console.log(document.querySelector("input[type=hidden][name=redirect]"));
                                                }
                                            }}
                                            className="App-input" placeholder="subdomain"/>
                                        <span className="App-domain">.laweval.app</span>
                                    </div>
                                    {errors.subdomain && <div className="error-message">This field is required</div>}
                                    <input ref={redirectRef} type="hidden" className="configuration" name="redirect" value="https://www.laweval.app"/>
                                    <input className="btn-submit" type="submit" value="Login"/>
                                </form>
                            </div>
                        </section>
                        <section className="App-footer">
                            <p>Don't have an account? <a href="https://www.laweval.com/contact-us/">Contact Us</a></p>
                        </section>
                    </div>
                } />
            </Routes>
        </Router>

    );
}

export default App;
