import React from 'react';
import './Welcome.css'; // Import CSS

function Welcome() {
    return (
        <div className="welcome-form">
            <iframe
                id="JotFormIFrame-220172277401042"
                title="Contact Us"
                onLoad={window.parent.scrollTo(0,0)}
                allowtransparency="true"
                allowfullscreen="true"
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/220172277401042"
                frameBorder="0"
                style={{ minWidth: '100%', height: '539px', border: 'none' }}
                scrolling="no"
            />
        </div>
    );
}

export default Welcome;
